//
// Mega menu
//

.mega-menu-panel {
  [role="menu"] {
    flex-direction: column;
  }

  .nav-link.active {
    text-decoration: underline;
    // stylelint-disable-next-line property-no-unknown
    text-decoration-thickness: $border-width;
    text-underline-offset: $border-width * 2;

    &::before {
      display: none;
    }
  }
}

.mega-menu .nav-link[aria-expanded="true"] {
  color: $navbar-dark-hover-color;
}

.nav-heading {
  display: none;
}

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);
    $upper: breakpoint-next($next, $grid-breakpoints);
    $upfix: breakpoint-infix($upper, $grid-breakpoints);

    &#{$infix} {
      .mega-menu .container#{$upfix} > .navbar-nav {
        flex-wrap: wrap;
        width: 100%;
      }

      .mega-menu-panel {
        will-change: height;
      }

      @include media-breakpoint-down($breakpoint) {
        .mega-menu {
          overflow: hidden;

          .container#{$upfix} {
            max-width: 100%;
          }

          > .navbar-nav {
            transform: translateX(0);
            will-change: transform;
            @include transition(transform .5s);
          }

          .nav-item {
            position: static;
            padding: 0;
          }

          .nav-link {
            display: flex;
            padding: $navbar-padding-y 0;
            font-size: $font-size-base;

            &::after {
              display: block;
              align-self: center;
              margin-left: auto;
            }
          }

          [aria-expanded="true"]::after {
            background-image: escape-svg($o-megamenu-arrow-icon-hover);
            transform: rotateZ(180deg);
          }

          [data-toggle="collapse"] {
            &::after {
              width: 1rem;
              height: .4375rem;
              content: "";
              background: escape-svg($o-megamenu-icon) no-repeat 50% / $o-megamenu-icon-size;
            }

            &:hover::after,
            &:focus::after,
            &:active::after,
            &.active::after,
            &[aria-expanded="true"]::after {
              background-image: escape-svg($o-megamenu-icon-active);
            }
          }

          .back::before,
          [aria-haspopup]::after {
            width: $o-megamenu-arrow-size;
            height: $o-megamenu-arrow-size;
            content: "";
            background: escape-svg($o-megamenu-arrow-icon) no-repeat 50% / 100%;
          }

          .back:hover::before,
          .back:focus::before,
          .back:active::before,
          [aria-haspopup]:hover::after,
          [aria-haspopup]:focus::after,
          [aria-haspopup]:active::after,
          [aria-haspopup].active::after {
            background-image: escape-svg($o-megamenu-arrow-icon-hover);
          }

          .back::before {
            display: block;
            align-self: center;
            margin: 0 map-get($spacers, 1) 0 -#{$spacer};
            transform: rotateZ(-180deg);
          }
        }

        .mega-menu-panel {
          [data-toggle="collapse"] {
            display: none;
          }

          .nav-heading {
            display: flex;
            padding: $navbar-padding-y 0  $navbar-padding-y $spacer;
            font-size: $h4-font-size;
            line-height: $h4-line-height;
            letter-spacing: $h4-spacing;
          }

          [role="menu"] {
            position: absolute;
            top: 0;
            left: 100%;
            display: none;
            width: 100%;
            @include transition(none);

            .nav-link {
              padding-left: map-get($spacers, 3);
            }

            .nav-item:nth-child(2) {
              border: 0;
            }
          }

          .all {
            flex: 1;
            border-top: $border-width / 2 solid $gray-900;
          }
        }
      }

      @include media-breakpoint-up($next) {
        .mega-menu-panel {
          position: absolute;
          top: $navbar-height;
          left: 0;
          z-index: $zindex-fixed;
          width: 100%;
          padding: map-get($spacers, 3) 0 map-get($spacers, 5);
          background-color: $black;

          .container#{$upfix} > .navbar-nav {
            padding: 0 map-get($spacers, 2);
          }

          .col {
            padding: 1rem 0;
          }

          [aria-haspopup] {
            padding: 0 0 map-get($spacers, 2);
            margin: 0 map-get($spacers, 5) map-get($spacers, 2) 0;
            border-bottom: $border-width / 2 solid $gray-700;
          }

          [role="menu"] .nav-link {
            padding: map-get($spacers, 1) map-get($spacers, 5) map-get($spacers, 1) 0;
          }

          .back {
            display: none;
          }
        }

        &.minimized .mega-menu-panel {
          top: 3.75rem;
        }
      }
    }
  }
}
