//
//  Footer
//

.o-footer {
  margin-top: map-get($spacers, 4);
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: $white;
  background-color: $black;

  .nav-link {
    padding: $nav-link-padding-y;
    font-size: $font-size-sm;

    &:not(:hover),
    &:not([href]),
    &:not([href]):hover {
      color: $white;
    }

    &:hover,
    &:focus {
      color: $orange-2;
    }
  }

  .o-footer-top,
  .o-footer-body,
  .o-footer-bottom {
    padding-top: map-get($spacers, 2);
    padding-bottom: map-get($spacers, 2);
  }

  .o-footer-top {
    border-bottom: $border-width / 2 solid $gray-900;
  }

  .o-footer-body {
    .row {
      margin-bottom: map-get($spacers, 2);
    }

    h3 {
      margin-top: map-get($spacers, 2);
      font-size: $font-size-sm;
    }

    p,
    p a {
      color: $white;
    }

    .flex-column .nav-link {
      padding: 0;
      line-height: 1.25;
    }
  }

  .o-footer-bottom {
    border-top: $border-width / 2 solid $gray-900;
  }
}
