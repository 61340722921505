// stylelint-disable property-blacklist
@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }

  // Boosted mod: PR in BS v5  → https://github.com/twbs/bootstrap/pull/29870
  @if $enable-prefers-reduced-motion-media-query {
    $isolate: false;
    @each $selector in $pseudo-vendor-prefixes {
      @if str-index(quote(#{&}), $selector) {
        $isolate: true;
      }
    }

    @if $isolate {
      @media (prefers-reduced-motion: reduce) {
        transition-duration: .01ms;
      }
    } @else {
      @extend %no-transition;
    }
  }
  // end mod
}
