// Disable animation if transitions are disabled
@if $enable-transitions {
  @keyframes progress-bar-stripes {
    from { background-position: $progress-height 0; }
    to { background-position: 0 0; }
  }
}

.progress {
  display: flex;
  height: $progress-height;
  overflow: hidden; // force rounded corners by cropping it
  @include font-size($progress-font-size);
  background-color: $progress-bg;
  @include border-radius($progress-border-radius);
  @include box-shadow($progress-box-shadow);

  // Boosted mod: using supporting colours for data display
  // stylelint-disable declaration-no-important
  .bg-success {
    background-color: $teal !important;
  }

  .bg-info {
    background-color: $cyan !important;
  }

  .bg-warning {
    background-color: $yellow-2 !important;
  }

  .bg-danger {
    background-color: $purple !important;
  }
  // stylelint-enable declaration-no-important
  // end mod
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  font-weight: $font-weight-bold; // Boosted mod
  color: $progress-bar-color;
  text-indent: map-get($spacers, 2); // Boosted mod
  white-space: nowrap;
  background-color: $progress-bar-bg;
  @include transition($progress-bar-transition);
}

// Boosted mod : progress is half height on mobile
@include media-breakpoint-down(md) {
  .progress {
    width: 100%;
    height: ($progress-height / 2);
    line-height: ($progress-height / 2);
  }
  .progress-bar {
    font-size: $font-size-sm;
    line-height: ($progress-height / 2);
  }
}
// end mod

.progress-bar-striped {
  @include gradient-striped();
  background-size: $progress-height $progress-height;
}

@if $enable-transitions {
  .progress-bar-animated {
    animation: progress-bar-stripes $progress-bar-animation-timing;
  }
}
