// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
// Boosted mod : add default color, disable-background, disabled-border and disabled-color option
@mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%), $disabled-background: $background, $disabled-border: $border, $disabled-color: color-yiq($background), $color: color-yiq($background)) {
  color: $color; // Boosted mod: allowing white on Orange…
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  // Boosted mod: replace hover by hover+focus because we remove box-shadow
  &:hover,
  &:focus,
  &.focus {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    outline-color: $hover-border;
  }
  // end mod

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    // Boosted mod: disabled theming with new variables
    color: $disabled-color;
    background-color: $disabled-background;
    border-color: $disabled-border;
    // end mod
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;
  }

  // Boosted mod: ensure focus visibility on active state
  &:not(:disabled):not(.disabled).active {
    &:focus,
    &.focus {
      color: color-yiq($hover-background);
      @include gradient-bg($hover-background);
      border-color: $hover-border;
      outline-color: $hover-border;
    }
  }
  // end mod
}

// Boosted mod: button-outline not allowed by Orange brand's buttons stencil

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: subtract($padding-y, 1px) $padding-x add($padding-y, 1px);
  @include font-size($font-size);
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
}
