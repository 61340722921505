.close {
  float: right;
  // Boosted mod
  width: map-get($spacers, 4);
  height: map-get($spacers, 4);
  padding: 0;
  margin-right: map-get($spacers, 2);
  // @include font-size($close-font-size);
  // font-weight: $close-font-weight;
  // line-height: 1;
  // color: $close-color;
  // text-shadow: $close-text-shadow;
  @include transition($transition-focus);
  background: escape-svg($close-icon) no-repeat 50% / #{map-get($spacers, 4)};
  border: 0;
  outline-offset: map-get($spacers, 2);
  opacity: 1;
  appearance: none;

  .bg-dark &,
  .navbar-dark & {
    background-image: escape-svg($close-icon-dark);

    &:hover,
    &:focus {
      outline-color: $gray-700;
    }
  }

  &:hover,
  &:focus {
    outline: $border-width solid $gray-500;
    outline-offset: -#{$border-width};

    &:not(:disabled):not(.disabled) {
      opacity: 1;
    }
  }

  &:hover {
    @include transition(none);
  }
  // end mod
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

// Boosted mod: included above ↑
// button.close {
//   padding: 0;
//   background-color: transparent;
// border: 0;
// appearance: none;
// }

// Future-proof disabling of clicks on `<a>` elements

// stylelint-disable-next-line selector-no-qualifying-type
a.close.disabled {
  pointer-events: none;
}
