// stylelint-disable font-family-no-missing-generic-family-keyword
//
// Carousel
// --------------------------------------------------

// native boostrap

.carousel {
  margin-bottom: 1.875rem;
}

.carousel .carousel-item img {
  width: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  top: auto;
  bottom: subtract(50%, 1.875rem);
  width: 3.75rem;
  height: 3.75rem;
  text-decoration: none;
  text-shadow: none;
  background-color: transparent;
  opacity: .8;
  @include transition($transition-carousel);

  // Boosted mod
  &:focus {
    outline: $border-width solid $input-focus-border-color;
    outline-offset: -#{$border-width};
  }
  // end mod
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  margin-top: -1.125rem;
  font-size: 1.875rem;
  color: $black;
  background-repeat: no-repeat;

  &::before {
    font-family: icon-orange;
  }
}

.carousel-control-prev-icon::before {
  content: "\e93f";
}

.carousel-control-next-icon::before {
  content: "\e93e";
}

.carousel-indicators {
  bottom: 0;
  align-items: center;

  &:hover {
    cursor: default;
  }

  li {
    flex-grow: 0;
    width: map-get($spacers, 2);
    height: map-get($spacers, 2);
    margin-right: .375rem;
    background-color: $black;
    border: $border-width / 2 solid $white;
    @include border-radius(50%);

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    background-color: theme-color("primary");
  }
}

// swiper
.swiper-button-prev,
.swiper-button-next {
  top: subtract(50%, 10px);
  width: auto;
  height: auto;
  cursor: pointer;
  background: none !important; // stylelint-disable-line declaration-no-important
  @include transition($transition-focus);

  &:focus {
    outline: $border-width solid $input-focus-border-color;
    outline-offset: -#{$border-width};
  }
}

.swiper-button-prev::before,
.swiper-button-next::before {
  font-family: icon-orange;
  font-size: $h2-font-size;
  content: "\e93f";
}

.swiper-button-next::before {
  content: "\e93e";
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  width: map-get($spacers, 2);
  height: map-get($spacers, 2);
  border: 0;
}

.swiper-pagination-bullet {
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: theme-color("primary");
}

.swiper-container {
  width: 100% !important; // stylelint-disable-line declaration-no-important
  margin-bottom: map-get($spacers, 4);
  overflow: hidden;
}

.swiper-wrapper {
  margin-bottom: map-get($spacers, 4);
}

.swiper-pagination {
  bottom: 6px;
}

.swiper-slide {
  .card {
    border: 0;
  }

  .card-img {
    padding: 0;

    img {
      width: 100%;
      height: auto;
      max-height: 40vh;
      object-fit: cover;
    }
  }

  .card-title {
    margin: 0;
    font-weight: $font-weight-bold;
  }

  .card-body {
    position: absolute;
    bottom: $spacer;
    left: $spacer * 2;
    background-color: $white;

    p {
      margin: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-container {
    overflow-x: hidden;
    overflow-y: visible;/* used for displaying pagination outside of container */
  }

  .swiper-slide {
    width: 80%;

    .card-img img {
      height: auto;
    }

    .card-body {
      position: static;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    width: .5rem;
    height: .5rem;
    border: 0;
  }
}
