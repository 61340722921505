// stylelint-disable selector-no-qualifying-type
.modal {

  .modal-content {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    border: 0;
  }

  .modal-header {
    position: relative;
    min-height: $spacer;
    padding: 0;
    margin-bottom: 2.5rem;
    border: 0;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 0;

    .card {
      display: flex;
      flex-direction: row;
      padding: 0;
      border: 0;
    }

    .card-body {
      padding: 0;
    }

    .card-icon {
      margin-right: $spacer;
    }

    [class^="svg"],
    [class^="svg"]::after {
      width: 2.875rem;
      height: 2.875rem;
    }

    [class^="svg"] {
      display: block;
    }

    [class^="svg"]::after {
      top: auto;
    }

    .card-title {
      margin-bottom: 1.25rem;
      font-size: 1.5rem;
    }
  }

  .modal-footer {
    align-self: flex-end;
    padding: 0;
    margin-top: 2.5rem;
    border: 0;

    .btn + .btn {
      margin-left: $spacer;
    }
  }
}

@include media-breakpoint-down(xs) {
  .modal {
    .modal-content {
      padding: $spacer;
    }

    .modal-header {
      margin: 0;
    }

    .modal-body {
      .card {
        flex-direction: column;
      }

      .card-icon {
        align-self: center;
        margin-right: 0;
      }

      .card-icon,
      [class^="svg"]::after {
        width: 4rem;
        height: 4rem;
      }

      .card-title {
        font-size: 1rem;
      }

      .card-body {
        margin-top: $spacer;
      }
    }

    .modal-footer {
      align-self: center;
    }
  }
}
