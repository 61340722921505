//
// o-link-arrow
//

.o-link-arrow {
  font-weight: $font-weight-bold;

  &::after,
  &::before {
    display: inline-block;
    width: $o-link-arrow-width;
    height: $o-link-arrow-height;
    vertical-align: middle;
    background-image: escape-svg($o-link-arrow-icon);
    background-repeat: no-repeat;
  }

  &:not(.back)::after {
    margin-left: $o-link-arrow-margin;
    content: "";

    /* rtl:remove */
    transform: rotate(180deg);
  }

  &.back::before {
    margin-right: $o-link-arrow-margin;
    content: "";
  }

  &:hover,
  &:focus {
    text-decoration: underline;

    &::after,
    &::before {
      background-image: escape-svg($o-link-arrow-icon-hover);
    }
  }
}
